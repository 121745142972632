<template>
   <b-container  class=" mt-5" >
     
     

    <b-row align-h="center">

<b-col cols="12" lg="6" xl="6" > 
     
      <b-card bg-variant="white" :header="$t('Login')" class="text-right">
          <br>
 <div class="text-center">
     <b-icon icon="person-badge-fill" scale="4.25" shift-v="8.25" aria-hidden="true"></b-icon>
 </div><br><br>

 <div>
  <b-alert :show="ShowError" dismissible variant="danger">
    {{errorMgs}}
  </b-alert>
</div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- input -->
      <b-form-group  :label="$t('Email')" label-for="input-1" :class="{ 'form-group--error': $v.form.email.$error }">
        <b-form-input v-model.trim="form.email" @input="setTouched('email')"  type="email" required></b-form-input>

        <div v-if="$v.form.email.$error">
         <b-form-invalid-feedback :state="$v.form.email.required"> {{$t('required_form')}} </b-form-invalid-feedback>
         <b-form-invalid-feedback :state="$v.form.email.email"> {{$t('email_form')}} </b-form-invalid-feedback>
         </div>
      </b-form-group>
       <!-- input -->
      <b-form-group  :label="$t('Password')" label-for="input-2">
        <b-form-input v-model.trim="form.password" @input="setTouched('password')" type="password" required ></b-form-input>
        <div v-if="$v.form.password.$error">
        <b-form-invalid-feedback :state="$v.form.password.required"> {{$t('required_form')}} </b-form-invalid-feedback>
        </div>
      </b-form-group>
      <br><router-link to="/forget_password">{{$t('Forget Your Password?')}}</router-link><br><br>
      <b-button type="submit" variant="primary"> <b-spinner v-if="isSendingForm" small></b-spinner> {{$t('Login')}}</b-button>
    
    </b-form>
       </b-card>
   </b-col>
   </b-row>
</b-container>
</template>

<script>
import axios from 'axios'
import { required, email  } from 'vuelidate/lib/validators'

  export default {
    data() {
      return {
        form: {
          email: '',
          password: ''
          
         
        },
        errorMgs:'',
        ShowError: false,
        show: true,
        isSendingForm:false
      }
    },
    validations: {
      form:{
        email: {
          required,
          email
        },
        password: {
          required
        }
    }
  },
    methods: {
    setTouched(theModel) {
       
      if(theModel == 'email' || theModel == 'all' ){this.$v.form.email.$touch()}
      if(theModel == 'password' || theModel == 'all'){this.$v.form.password.$touch()}
      
    },
      onSubmit(event) {
        event.preventDefault()
        this.setTouched('all');
        if (!this.$v.$invalid) {

       this.isSendingForm = true;
        //alert(JSON.stringify(this.form))
         
        axios.post(this.$store.state.backendUrl+'api/login',this.form, {
            headers: {
         //   'Authorization': 'Bearer' + 'Your Bearer Pssword',
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                if(response.Error){this.ShowError=true;this.errorMgs = this.$t('error_login'); this.isSendingForm = false;}
                else{
                  this.$store.commit('saveLogin',{"token":response.data.success.token,
                  "name":response.data.success.name,
                  "permissions":response.data.success.permissions
                  
                  });
                  this.$router.push('/')
                //  console.log(this.$store.state);
                   this.isSendingForm = false;
                   /**** */
              
                   /**** */
               
                }
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = this.$t('error_login') +" -- "+ error;
                  this.isSendingForm = false;
            });
      
           }
      },
     
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>